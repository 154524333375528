<template>
  <div
    v-editable="blok"
    class="group relative mx-auto aspect-video h-auto w-full max-w-[--width] overflow-hidden rounded-double border-2 border-blue-dark border-transparent bg-blue-dark transition-colors"
    :class="{ 'hover:border-text hover:bg-text': !played }"
    :style="`--width: ${width}px;`"
  >
    <audoora-video
      :id="`video-${blok._uid}`"
      ref="videoEl"
      :url="`https://static-resources.audoora.de/videos/website/${blok.video}/wide`"
      :class="played ? 'visible opacity-100' : 'invisible opacity-0'"
      :tabindex="played ? 0 : -1"
      @play="played = true"
    />
    <button
      class="absolute left-0 top-0 z-10 size-full transition-opacity @container aria-hidden:pointer-events-none aria-hidden:opacity-0"
      :aria-controls="`video-${blok._uid}`"
      aria-label="Video starten"
      :aria-hidden="played"
      :disabled="played"
      :tabindex="played ? -1 : 0"
      type="button"
      @click="playVideo()"
    >
      <sb-image
        v-if="blok.previewImage?.filename"
        :blok="blok.previewImage"
        format="webp,png"
        :width="width"
        :height="height"
        decorative
        class="absolute left-0 top-0 z-[-1] size-full rounded-double object-cover object-center"
      />
      <div
        class="text-shadow absolute left-0 top-0 flex size-full flex-col items-center justify-center gap-8 rounded-double bg-blue-dark/30 text-white transition-opacity"
        :class="{ 'opacity-0 group-hover:opacity-100': blok.hidePlayIcon }"
      >
        <nuxt-icon name="play" class="text-4xl @sm:text-5xl" />
        <span class="text-xl font-semibold empty:hidden @md:text-3xl">{{ blok.previewText }}</span>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { StaticVideoStoryblok } from "~/storyblok-component-types"
import type audooraVideo from "~/components/audoora-video.vue"

const props = defineProps<{ blok: StaticVideoStoryblok; }>()
const emits = defineEmits<{ play: []; }>()

const videoEl = ref<typeof audooraVideo>()
const played = ref(false)

const width = computed(() => Math.round(+(props.blok.width || 0 || 1024)))
const height = computed(() => width.value * 0.5625)

async function playVideo() {
  videoEl.value?.play()
  emits("play")
  await nextTick()
  videoEl.value?.focus()
}
</script>

<style scoped>
.text-shadow {
  text-shadow: 0 0 4px theme("colors.blue.dark");
}
</style>
