<template>
  <video
    ref="videoEl"
    class="h-auto max-h-full w-full bg-green-lighter"
    controls
    disable-picture-in-picture
    disableremoteplayback
    playsinline
    preload="metadata"
    @play="$emit('play')"
    @pause="$emit('pause')"
  >
    <template v-if="isStaticResourcesDirectory">
      <source v-if="!isSafari" :src="`${url}/video.webm`" type="video/webm" media="(min-device-pixel-ratio:2)" />
      <source v-if="!isSafari" :src="`${url}/video-sm.webm`" type="video/webm" />
      <source :src="`${url}/video.mp4`" type="video/mp4" media="(min-device-pixel-ratio:2)" />
      <source :src="`${url}/video-sm.mp4`" type="video/mp4" />
      <slot />
    </template>
    <template v-else>
      <source :src="url" />
      <slot />
    </template>
  </video>
</template>

<script setup lang="ts">
const props = defineProps<{ url: string; }>()
defineEmits<{
  play: []
  pause: []
}>()
defineExpose({
  play,
  pause,
  stop,
  focus: () => videoEl.value?.focus(),
})

useSeoMeta({
  ogVideo: `${props.url}/video.mp4`,
  ogVideoType: "video/mp4",
})

// video directories on https://static-resources.audoora.de/ should follow a specific format
const isStaticResourcesDirectory = computed(
  () => props.url.startsWith("https://static-resources.audoora.de/videos/") && !/.\\w+$/.test(props.url),
)

const isSafari = ref(useDevice().isSafari)
const videoEl = ref<HTMLVideoElement>()

function play() {
  videoEl.value?.play()
}

function pause() {
  videoEl.value?.pause()
}

function stop() {
  if (videoEl.value) {
    videoEl.value.pause()
    videoEl.value.currentTime = 0
  }
}
</script>
